
* {
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  color: #070D32;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
}

a {
  text-decoration: none;
}

.activeLink {
  color: #070D32;
}

.Modal {
  border: 1px solid red !important;
}